import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Option as DOption } from "react-tailwindcss-select/dist/components/type";
import Swal from "sweetalert2";
import translator from "../../../Components/Extra/Translation/Translate";
import { countryList } from "../../../Models/CountryList";
import { LoyaltyReduction } from "../../../Models/Fidelity";
import { AirtimeData } from "../../../Models/Gateway";
import MerchantCategory from "../../../Models/MerchantCategory";
import { IFilterByCategoryAndCountry, IPayment } from "../../../Models/Payment";
import { MerchantPublic } from "../../../Models/Roles/Merchant";
import { UserCryptoWallet } from "../../../Models/UserWallet";
import alert_message from "../../../Services/Alert/AlertMessage";
import MerchantCategoryService from "../../../Services/Roles/MerchantService/MerchantCategoryService";
import RoutesNames from "../../../Services/RoutesNames";
import UtilsService, { formatAmount } from "../../../Services/UtilsService";
import WithdrawalService from "../../../Services/Wallets/Transaction/WithdrawalService";
import WalletService from "../../../Services/Wallets/WalletService";
import KpButton from "../../Components/Inputs/KpButton";
import KpInput from "../../Components/Inputs/KpInput";
import KpSelect, {
    KpSelectOption,
    createSelectOption,
} from "../../Components/Inputs/KpSelect";
import { KpPaymentIcon } from "../../Icons/MosaicIcons";
import AirtimeForm from "../../Partials/Payment/Mobile/AirtimeForm";
import { useAppProvider } from "../../Provider/AppProvider";
import { useAssets } from "../../Provider/AssetsProvider";
import { useFidelity } from "../../Provider/FidelityProvider";

interface PaymentForm {
    country: KpSelectOption | null;
    operator: KpSelectOption | null;
    phone: string | undefined;
    category: KpSelectOption | null;
    user: KpSelectOption | null;
    wallet: KpSelectOption | null;
    amount: number;
    fee: number;
}

const PaymentForm = () => {
    const { translate } = translator();
    const { setProcessing, finalizeProcessing, processing } = useAppProvider();
    const { assets, refreshAssets } = useAssets();
    const navigate = useNavigate();
    const { getReduction } = useFidelity();

    const methods = useForm<PaymentForm>({
        defaultValues: {
            country: null,
            category: null,
            user: null,
            wallet: null,
            amount: 0,
            fee: 0,
        },
    });

    const country = methods.watch("country");
    const category = methods.watch("category");
    const operator = methods.watch("operator");
    const phone = methods.watch("phone");
    const user = methods.watch("user");
    const amount = methods.watch("amount");
    const wallet = methods.watch("wallet");
    const fee = methods.watch("fee");

    // is_airtime => Vous recevrez , Votre compte sera debite de , Frais x %
    // !is_airtime => Votre compte sera debite de , Frais x %

    const is_airtime =
        category &&
        (category.value.toLowerCase() === "airtime" ||
            category.label.toLowerCase() === "airtime");

    const onSubmited = async (data: PaymentForm) => {
        if (processing) return;
        const { value } = await Swal.fire({
            title: translate("Swap", "Transaction_Password"),
            text: translate("Swap", "Enter_Your_Transaction_Password"),
            input: "password",
            inputPlaceholder: translate("Swap", "Transaction_Password"),
            confirmButtonColor:
                "radial-gradient(circle at center,#3695D2 0%,#303B8E 100%)",
        });

        if (value.length === 0) return;

        if (is_airtime) {
            const payload: AirtimeData = {
                wallet: fromWallet?.abbreviation || "",
                amount: amount,
                data: {
                    country: country?.value || "",
                    operator: operator?.value || "",
                    phone: phone || "",
                },
                security: {
                    transaction_password: value,
                },
            };

            setProcessing(true);

            const result = WithdrawalService.processAirtime(payload);
            alert_message
                .promise("Encours ...", result)
                .catch(() => {
                    finalizeProcessing();
                })
                .then(() => {
                    navigate(RoutesNames.Assets);
                });
        } else {
            if (!data.user) return;

            const payload: IPayment = {
                partner: data.user.value,
                amount: data.amount,
                wallet: fromWallet?.abbreviation || "",
                transaction_password: value,
            };

            setProcessing(true);

            const result = WalletService.payment(payload);
            alert_message
                .promise("Paiement encours ...", result)
                .catch(() => {
                    finalizeProcessing();
                })
                .then(() => {
                    navigate(RoutesNames.Assets);
                });
        }
    };

    const [categories, setCategories] = useState<Array<DOption>>([]);
    const [users, setUsers] = useState<Array<DOption>>([]);
    const [merchants, setMerchants] = useState<Array<MerchantPublic>>([]);

    const merchant = useMemo<MerchantPublic | undefined>(() => {
        return merchants.find((m) => m.uid === user?.value);
    }, [merchants, user]);

    const wallets = useMemo<Array<KpSelectOption>>(() => {
        let asset_actives = assets;
        if (is_airtime && country) {
            asset_actives = assets.filter(
                (asset) =>
                    asset.type === "fiat" &&
                    asset.payment_method_config?.withdraw.airtime.country.includes(
                        country.value,
                    ),
            );
        }
        const res = asset_actives.map((asset) =>
            createSelectOption(asset, "crypto"),
        );

        return res;
    }, [assets, country]);

    const fromWallet = useMemo<UserCryptoWallet | undefined>(() => {
        return assets.find((asset) => asset.abbreviation === wallet?.value);
    }, [wallet?.value]);

    const getAllCategories = async () => {
        try {
            const res = await MerchantCategoryService.getAll();
            const optionsList: DOption[] = res.map(
                (value: MerchantCategory) => ({
                    label: value.name,
                    value: value.id.toString(),
                }),
            );
            setCategories(optionsList);
        } catch (err: any) {}
    };

    const getAllUser = async (filter: IFilterByCategoryAndCountry) => {
        methods.setValue("user", null);
        setMerchants([]);
        setUsers([]);
        try {
            const res =
                await MerchantCategoryService.findAllByCountryAndCategory(
                    filter,
                );
            const optionsList: DOption[] = res.map((value: MerchantPublic) => ({
                label: `${value.name} (${value.uid})`,
                value: value.uid,
            }));
            setUsers(optionsList);
            setMerchants(res);
        } catch (err: any) {}
    };

    const ableToSubmit = useMemo<boolean>(() => {
        if (!country || country.value.length === 0) return false;
        if (!category || category.value.length === 0) return false;

        if (is_airtime) {
            if (!phone || phone.length < 5) return false;
            if (!operator || operator.value.length === 0) return false;
        } else {
            if (!user || user.value.length === 0) return false;
        }

        return fromWallet !== undefined && amount > 0 && !processing;
    }, [country, category, user, amount, phone, operator]);
    const fees = useMemo<{
        fee2: number;
        fee_amount: number;
        reduction: number;
        reduction_amount: number;
        converted_amount_with_fee: string;
        reduced_fee: number;
    }>(() => {
        const fee2 = fee;
        let fee_amount = 0;
        const reduction =
            getReduction(LoyaltyReduction.PAIEMENT_MARCHAND_FEE_REDUCTION) || 0;
        const reduction_amount = 0;
        const converted_amount_with_fee = 0;
        let reduced_fee = 0;

        fee_amount = UtilsService.getFee(amount, fee2);

        if (reduction > 0) {
            reduced_fee = fee2 - UtilsService.getFeeReduction(fee2, reduction);
            fee_amount = UtilsService.getFee(amount, reduced_fee);
        }

        return {
            fee2,
            fee_amount,
            reduction,
            reduction_amount,
            converted_amount_with_fee: UtilsService.formatAmountV2(
                converted_amount_with_fee,
                2,
            ),
            reduced_fee,
        };
    }, [fromWallet, amount]);

    useEffect(() => {
        Promise.all([getAllCategories(), refreshAssets()]).then(() => {
            finalizeProcessing();
        });
    }, []);

    useEffect(() => {
        if (country && category) {
            getAllUser({
                country: country.value,
                categoryId: Number(category.value),
            });
        }
    }, [country, category]);

    useEffect(() => {
        if (merchant) {
            methods.setValue("fee", merchant.fee);
        } else if (!is_airtime) {
            methods.setValue("fee", 0);
        }
    }, [merchant?.uid]);

    return (
        <FormProvider {...methods}>
            <form
                onSubmit={methods.handleSubmit(onSubmited)}
                className="col-span-12"
            >
                <div className="col-span-12">
                    <div className="intro-y box">
                        <div className="-mx-5 flex flex-col border-b border-slate-200/60 pb-5 lg:flex-row relative p-3">
                            <div className="mt-6 flex-1 border-t border-slate-200/60 px-5 pt-5 lg:mt-0 lg:border-t-0 lg:pt-0">
                                <div className="mt-1 flex flex-col items-center justify-center lg:items-start">
                                    <div className="intro-y w-full w-selects">
                                        <KpSelect
                                            label={translate(
                                                "Admin_Roles",
                                                "Country",
                                            )}
                                            options={countryList}
                                            id="country"
                                            name="country"
                                        />
                                    </div>
                                    <div className="intro-y w-full mt-1 w-selects">
                                        <div>
                                            <KpSelect
                                                label={translate(
                                                    "Admin_Assets",
                                                    "Category",
                                                )}
                                                options={categories}
                                                id="category"
                                                name="category"
                                            />
                                        </div>
                                    </div>
                                    <div className="intro-y w-full mt-2 w-selects">
                                        <KpSelect
                                            label={translate(
                                                "TRANSFERT",
                                                "WALLET",
                                            )}
                                            options={wallets}
                                            id="wallet"
                                            name="wallet"
                                        />
                                    </div>
                                    <div className="intro-y w-full mt-4">
                                        <KpInput
                                            label={translate("Swap", "Amount")}
                                            name="amount"
                                            type="number"
                                            options={{
                                                required: true,
                                                min: 4,
                                            }}
                                            inputProps={{
                                                required: true,
                                                min: 4,
                                            }}
                                            decimal={2}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-6 flex-1 border-l border-b-dark border-slate-200/60 px-5 pt-5 lg:mt-0 lg:border-t-0 lg:pt-0 relative">
                                <div className="flex flex-col items-center justify-center lg:items-start">
                                    {is_airtime ? (
                                        <>
                                            <AirtimeForm></AirtimeForm>
                                        </>
                                    ) : (
                                        <>
                                            <div className="intro-y w-full mt-2 w-selects">
                                                <KpSelect
                                                    label={translate(
                                                        "Admin_Roles",
                                                        "Merchant",
                                                    )}
                                                    options={users}
                                                    id="user"
                                                    name="user"
                                                    placeholder={translate(
                                                        "PAYMENT",
                                                        "CHOOSE_MERCHANT",
                                                    )}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>

                                <div className="mt-3 flex w-full items-center justify-end truncate sm:whitespace-normal  md:right-[1.25rem]  lg:right-[1.25rem]  xl:right-[1.25rem]">
                                    <div>
                                        <KpButton
                                            type="submit"
                                            className={` mb-2 w-32`}
                                            label={translate("Swap", "Save")}
                                            disabled={!ableToSubmit}
                                        >
                                            <KpPaymentIcon />
                                        </KpButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="intro-y box mt-5">
                        <div className="flex flex-col lg:flex-row relative p-3">
                            <div className="mt-6 flex-1 lg:mt-0 lg:border-t-0 lg:pt-0 relative">
                                <div className=" flex flex-col items-center justify-center lg:items-start">
                                    {wallet && (
                                        <>
                                            <div className="mb-3 flex w-full items-center truncate sm:whitespace-normal">
                                                <div className="relative w-full">
                                                    {is_airtime
                                                        ? `${translate(
                                                              "PAYMENT",
                                                              "You_will_receive",
                                                          )} ${amount} ${
                                                              wallet?.value
                                                          }`
                                                        : `${translate(
                                                              "PAYMENT",
                                                              "Your_account_will_be_debited_of",
                                                          )} ${
                                                              amount +
                                                              UtilsService.getFee(
                                                                  amount,
                                                                  fee,
                                                              )
                                                          } ${wallet?.value}`}
                                                </div>
                                            </div>

                                            <div className="mb-3 flex w-full items-center truncate sm:whitespace-normal">
                                                <div className="relative w-full">
                                                    {is_airtime
                                                        ? `${translate(
                                                              "PAYMENT",
                                                              "Your_account_will_be_debited_of",
                                                          )} ${
                                                              amount +
                                                              UtilsService.getFee(
                                                                  amount,
                                                                  fees.reduced_fee ||
                                                                      fee,
                                                              )
                                                          } ${wallet?.value}`
                                                        : `${translate(
                                                              "PAYMENT",
                                                              "Fee",
                                                          )} ${UtilsService.getFee(
                                                              amount,
                                                              fee,
                                                          )} ${wallet?.value}`}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <div className="flex w-full items-center truncate sm:whitespace-normal">
                                        <div className="relative w-full">
                                            {translate("PAYMENT", "Fee")}:{" "}
                                            {fees.reduction > 0 &&
                                            amount > 0 ? (
                                                <>
                                                    <span className="line-through">
                                                        {formatAmount(fee, 2)}%
                                                    </span>{" "}
                                                    {formatAmount(
                                                        fees.reduced_fee,
                                                    )}
                                                    %
                                                </>
                                            ) : (
                                                <span>
                                                    {formatAmount(fees.fee2, 2)}
                                                    %{" "}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </FormProvider>
    );
};

export default PaymentForm;
