import React from "react";
import { Outlet } from "react-router-dom";
import TabNav, { TabItem } from "../../../../Components/Extra/Tab/TabNav";
import translator from "../../../../Components/Extra/Translation/Translate";
import BreadCrumbV2, {
    Ariane,
} from "../../../../Layouts/Account/BreadCrumb/BreadCrumbV2";

const GatewayConfig: React.FC = () => {
    const { translate } = translator();

    const Arianes: Ariane[] = [
        {
            path: "#",
            name: "Admin",
        },
        {
            path: "/admin/setting",
            name: "Setting",
        },
        {
            path: "",
            name: "Payment",
        },
    ];

    const navigation: TabItem[] = [
        // {
        //     path: "kalypay",
        //     name: "Kalypay",
        // },
        {
            path: "visa",
            name: "Visa",
        },
        {
            path: "crypto",
            name: "Crypto",
        },
        {
            path: "virement",
            name: "Virement",
        },
        {
            path: "mobile",
            name: "Mobile",
        },
        {
            path: "perfect-money",
            name: "Perfect Money",
        },
        {
            path: "payeer",
            name: "Payeer",
        },
        {
            path: "gpt",
            name: "GPT",
        },
        {
            path: "alchemy",
            name: "Alchemy",
        },
        {
            path: "instant-bill",
            name: "Instant Bill",
        },
        {
            path: "paga",
            name: "Paga",
        },
        {
            path: "kalyssi",
            name: "Kalyssi",
        },
    ];

    return (
        <>
            <BreadCrumbV2 arianes={Arianes} />
            <h2 className="intro-y mt-10 bg-white p-2 rounded text-lg font-medium">
                {translate("Setting", "Payment")}
            </h2>
            <div className="mt-5 pt-5">
                <TabNav items={navigation} />
                <div className="intro-y col-span-12 box">
                    <Outlet />
                </div>
            </div>
        </>
    );
};

export default GatewayConfig;
